<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2022-04-20 15:22:57
 * @LastEditTime: 2022-07-27 13:45:21
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="write-box">
    <el-form ref="writeForm" label-width="130px" :model="writeForm">
      <el-form-item
        label="联系人"
        prop="linkmanrpid"
        :rules="
          secondWorkCode == 6
            ? []
            : [
                {
                  required: true,
                  message: '请选择联系人',
                  trigger: ['blur', 'change']
                }
              ]
        "
      >
        <div class="select-content">
          <e6-vr-select
            v-model="writeForm.linkmanrpid"
            :data="contactList"
            placeholder="联系人"
            title=""
            :props="{
              id: 'contactId',
              label: 'contactNamePhone'
            }"
            clearable
          ></e6-vr-select>
          <el-button type="text" style="margin-left: 10px" @click="addConcat">
            添加联系人
          </el-button>
        </div>
      </el-form-item>
      <el-form-item
        label="联系电话"
        prop="contactPhone"
        :rules="
          secondWorkCode == 6
            ? []
            : [
                {
                  required: true,
                  message: '请输入联系电话',
                  trigger: ['blur', 'change']
                }
              ]
        "
      >
        <el-input
          placeholder="联系电话"
          v-model="writeForm.contactPhone"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="联系地址"
        prop="defaultCity"
        :rules="[
          {
            required: true,
            message: '请选择联系地址',
            trigger: ['blur', 'change']
          }
        ]"
        v-if="secondWorkCode == 1 || secondWorkCode == 2"
      >
        <div class="select-content">
          <e6-vr-select
            style="width:300px"
            v-model="writeForm.defaultCity"
            :data="addressList"
            placeholder="联系地址"
            title="联系地址"
            virtual
            clearable
            :is-title="true"
            remote
            @filterChange="handleLoadAddressFilter"
            @change="handleCitySelect"
            :props="{
              id: 'fullName',
              label: 'fullName'
            }"
          ></e6-vr-select>
          <el-button
            type="text"
            style="margin-left: 10px"
            @click="selectCustomerAddress"
          >
            选择客户常用地址
          </el-button>
        </div>
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="insAddress"
        :rules="[
          {
            required: true,
            message: '请输入详细地址',
            trigger: ['blur', 'change']
          }
        ]"
        v-if="secondWorkCode == 1 || secondWorkCode == 2"
      >
        <el-input
          placeholder="详细地址"
          v-model="writeForm.insAddress"
        ></el-input
      ></el-form-item>
      <el-form-item
        label="指派类型"
        prop="solueIsDept"
        v-if="secondWorkCode == 6"
        :rules="[
          {
            required: true,
            message: '请选择指派类型',
            trigger: ['blur', 'change']
          }
        ]"
      >
        <el-radio-group v-model="writeForm.solueIsDept">
          <el-radio :label="0">员工</el-radio>
          <el-radio :label="1">部门</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="指派部门"
        prop="departmentId"
        v-if="writeForm.solueIsDept == 1 && secondWorkCode == 6"
        :rules="[
          {
            required: true,
            message: '请选择指派部门',
            trigger: ['blur', 'change']
          }
        ]"
      >
        <!-- 部门下拉框 -->
        <e6-select
          v-model="writeForm.departmentId"
          :dataList="treeData"
          :filterable="true"
          :slotTemplate="'tree'"
          :multiple="false"
          :issingleleaf="false"
          ref="t_select"
        ></e6-select>
      </el-form-item>
      <el-form-item
        label="指派处理人"
        prop="solveRpid"
        v-if="writeForm.solueIsDept == 0 && secondWorkCode == 6"
        :rules="[
          {
            required: true,
            message: '请选择指派处理人',
            trigger: ['blur', 'change']
          }
        ]"
      >
        <all-user-search
          v-model="writeForm.solveRpid"
          clear
          placeholder="指派处理人"
          title="指派处理人"
          :initValue="[userInfo]"
          :propsConfig="{
            id: 'employeeId',
            label: 'userName'
          }"
        >
        </all-user-search>
      </el-form-item>
      <el-form-item
        label="要求完成时间"
        prop="expecttime"
        :rules="[
          {
            required: true,
            message: '请选择要求完成时间',
            trigger: ['blur', 'change']
          }
        ]"
      >
        <el-date-picker
          v-model="writeForm.expecttime"
          type="datetime"
          placeholder="选择日期"
          value-format="timestamp"
          popper-class="special"
          :picker-options="pickerOptions1"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="问题时限"
        prop="replyLimit"
        v-if="secondWorkCode == 6"
        :rules="[
          {
            required: true,
            message: '请选择问题时限',
            trigger: ['blur', 'change']
          }
        ]"
      >
        <el-popover
          placement="bottom"
          width="600"
          trigger="click"
          @show="handleShow"
          @hide="handleHide"
          ref="popover"
        >
          <el-table :data="selectTable" @row-click="rowClick" height="500">
            <el-table-column
              width="160"
              property="replyTime"
              label="回复时限"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              width="100"
              property="questionType"
              label="问题类型"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              width="160"
              property="specificItem"
              label="具体项目"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              width="160"
              property="questionContext"
              label="问题内容"
              header-align="center"
              align="center"
            ></el-table-column>
          </el-table>
          <el-input
            placeholder="选择问题时限"
            slot="reference"
            style="width:300px;"
            ref="selectInput"
            @input="handleLoadLimtFilter"
            v-model="writeForm.replyLimit"
          >
            <i slot="suffix" class="e6-icon-arrow-b_line" v-if="showSel"></i>
            <i slot="suffix" class="e6-icon-arrow-t_line" v-else></i>
          </el-input>
        </el-popover>
      </el-form-item>
      <el-form-item
        label="备注"
        prop="remark"
        v-if="secondWorkCode == 1 || secondWorkCode == 2"
      >
        <el-input
          v-model="writeForm.remark"
          placeholder="请输入备注"
          type="textarea"
          :rows="3"
          style="width: 400px"
          maxlength="100"
        ></el-input>
      </el-form-item>
    </el-form>

    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      @handleClose="handleClose"
      @updateList="updateList"
      :isNeedReq="true"
      :corpId="corpInfo.corpId"
      :corpName="corpInfo.corpName"
    ></add-concat-dialog>
    <!-- 客户地址弹框 -->
    <customer-address
      :selectAddressDialog="selectAddressDialog"
      :corpId="corpInfo.corpId"
      :corpName="corpInfo.corpName"
      @handleClose="handleClose"
      @updateAddress="updateAddress"
    ></customer-address>
  </div>
</template>

<script>
import base from "@/mixins/base";
// import minxins from "../minxins";
import { mapState } from "vuex";
import { getAddressData, getFrameworkTree, getLimitList } from "@/api";
import { printError } from "@/utils/util";
import customerAddress from "./customerAddress.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "writeForm",
  components: { AddConcatDialog, customerAddress, allUserSearch },
  data() {
    return {
      writeForm: {
        linkmanrpid: "", //联系人
        contactPhone: "", //联系电话
        defaultCity: "", //联系地址
        insAddress: "", //详细地址
        replyLimit: "", //回复时限
        expecttime: "", //要求完成时间
        solveRpid: "", //指派处理人
        solueIsDept: 0, //指派类型1部门0员工
        departmentId: [], //指派部门，
        remark: "" //备注
      },
      addContactVisible: false,
      selectAddressDialog: false,
      //   咨询回复的表单
      treeData: [], //
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      addressList: [],
      /******问题时限下拉框********/
      showSel: false, //是否显示下拉框
      selectTable: [], //回复时限下拉框数据
      searchValue: ""
    };
  },
  mixins: [base],
  props: ["corpInfo", "contactList", "secondWorkCode", "serverResultCode"],
  computed: {
    ...mapState(["userInfo"])
  },
  watch: {
    // 监听联系人名称更改
    "writeForm.linkmanrpid": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          let concatInfo = this.contactList.find(item => {
            return item.contactId == val;
          });
          this.writeForm.contactPhone = concatInfo.phone1
            ? concatInfo.phone1
            : "";
          this.writeForm.contactName = concatInfo.contactName;
          if (concatInfo.addressList.length) {
            //省
            this.writeForm.provinceName =
              concatInfo.addressList[0].provinceName;
            this.writeForm.province = concatInfo.addressList[0].provinceId;
            //市
            this.writeForm.cityName = concatInfo.addressList[0].cityName;
            this.writeForm.city = concatInfo.addressList[0].cityId;
            //区
            this.writeForm.insCountyName = concatInfo.addressList[0].areaName;
            this.writeForm.insCounty = concatInfo.addressList[0].areaId;
            //街道
            this.writeForm.streetName = concatInfo.addressList[0].streetName;
            this.writeForm.street = concatInfo.addressList[0].streetId;
            //详细地址
            this.writeForm.insAddress = concatInfo.addressList[0].address;
            //默认地址
            this.writeForm.defaultCity =
              concatInfo.addressList[0].provinceName +
              concatInfo.addressList[0].cityName +
              concatInfo.addressList[0].areaName +
              concatInfo.addressList[0].streetName;
            this.loadAddressData(this.writeForm.defaultCity);
          }
        }
      }
    },
    // 监听客户名称更改
    "coepInfo.corpId": {
      immediate: true,
      handler(val, oldVal) {
        if (val !== "" && val != oldVal) {
          this.writeForm.linkmanrpid = "";
          this.writeForm.contactPhone = "";
        }
      }
    },
    secondWorkCode: {
      immediate: true,
      handler(val) {
        if (val == 1 || val == 2) {
          this.writeForm.expecttime = new Date().setHours(
            new Date().getHours() + 4
          );
        } else {
          this.writeForm.expecttime = "";
        }
      }
    }
  },
  methods: {
    //初始化数据
    async initData() {
      let promiseList = [
        getFrameworkTree(), //获取部门结构
        this.getLimitListReq("")
      ];
      let [frameworkRes] = await Promise.all(promiseList);
      this.handleTreeData(frameworkRes.data.children);
    },
    //获取问题时限
    handleLoadLimtFilter: _.debounce(async function(val) {
      this.getLimitListReq(val);
    }, 300),
    //查询回复时限下拉框数据
    async getLimitListReq(val = "") {
      try {
        let res = await getLimitList({ searchValue: val });
        //基本信息
        this.selectTable = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理树形结构
    handleTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        data[i]["label"] = data[i].name;
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    //添加联系人
    addConcat() {
      if (!this.corpInfo.corpId) {
        this.$message.warning("请先选择客户名称");
        return;
      }
      this.addContactVisible = true;
    },
    //添加联系人之后更新下拉框
    updateList() {
      this.$emit("updateContactList");
    },
    //点击客户地址
    selectCustomerAddress() {
      if (!this.corpInfo.corpId) {
        this.$message.warning("请先选择客户");
        return;
      }
      this.selectAddressDialog = true;
    },
    //更新客户地址
    updateAddress(val) {
      this.loadAddressData(val.cityName, 1);
      this.writeForm.insAddress = val.addrName;
    },
    //加载地址列表
    async loadAddressData(val = "", type) {
      try {
        let res = await getAddressData({
          addressName: val
        });
        //获取地址下拉框
        this.addressList = this.getFreezeResponse(res, {
          path: "data"
        });
        if (type === 1 && this.addressList.length) {
          //type有值代表查询回写
          this.writeForm.defaultCity = val;
          let address = this.addressList[0];
          this.writeForm.province = address.provinceCode;
          this.writeForm.provinceName = address.provinceName;
          this.writeForm.city = address.cityCode;
          this.writeForm.cityName = address.cityName;
          this.writeForm.insCounty = address.countyCode;
          this.writeForm.insCountyName = address.countyName;
          this.writeForm.streetId = address.streetCode;
          this.writeForm.streetName = address.streetName;
        }
      } catch (error) {
        printError(error);
      }
    },
    //过滤地址数据
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.writeForm.defaultCity = "";
        this.writeForm.province = "";
        this.writeForm.provinceName = "";
        this.writeForm.city = "";
        this.writeForm.cityName = "";
        this.writeForm.insCounty = "";
        this.writeForm.insCountyName = "";
        this.writeForm.streetId = "";
        this.writeForm.streetName = "";
        this.loadAddressData(val);
      }
    }, 300),
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.writeForm.province = val.provinceCode;
      this.writeForm.provinceName = val.provinceName;
      this.writeForm.city = val.cityCode;
      this.writeForm.cityName = val.cityName;
      this.writeForm.insCounty = val.countyCode;
      this.writeForm.insCountyName = val.countyName;
      this.writeForm.streetId = val.streetCode;
      this.writeForm.streetName = val.streetName;
      this.writeForm.defaultCity = val.fullName;
    },

    //点击下拉输入框
    clickInput() {
      this.$refs.selectInput.blur();
    },
    //下拉框显示
    handleShow() {
      this.showSel = true;
    },
    //下拉框隐藏
    handleHide() {
      this.showSel = false;
    },
    //点击下拉框table
    rowClick(row) {
      this.writeForm.replyLimit = row.replyTime;
      this.writeForm.project = row.specificItem;
      this.writeForm.questionContent = row.questionContext;
      this.writeForm.questionType = row.questionType;
      this.$refs.popover.doClose();
    },
    // 关闭弹框
    closeDialog(visibleName) {
      this[visibleName] = false;
    }
  },
  created() {
    console.log(this.userInfo, "===");
    this.writeForm.solveRpid = this.userInfo.employeeId;
    this.initData();
  }
};
</script>
<style lang="scss" scoped>
.write-box {
  padding-left: 20px;
  box-sizing: border-box;
  /deep/.el-form {
    .e6-vr-select {
      width: 260px !important;
    }
    .el-form-item {
      .el-input {
        width: 260px;
      }
      .el-textarea {
        width: 400px;
      }
    }
  }
}
</style>
